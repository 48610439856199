<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          {{title}}
        </h5>
        <div class="row">
          <div class="col-md-4">
            <b-form-group id="input-group-photo">
              <label class="col-xl-3 col-lg-5 col-form-label pl-0 pt-0"
                >Gambar</label
              >
              <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                <div
                  class="image-input image-input-outline"
                  id="kt_profile_avatar"
                >
                  <div class="image-input-wrapper">
                    <img :src="photo" alt="" />
                  </div>
                  <label
                    class="
                      btn
                      btn-xs
                      btn-icon
                      btn-circle
                      btn-white
                      btn-hover-text-primary
                      btn-shadow
                    "
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i class="fa fa-pen icon-sm text-muted"></i>
                    <input
                      type="file"
                      name="profile_avatar"
                      accept=".png, .jpg, .jpeg"
                      @change="onFileChange($event)"
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                  <span
                    class="
                      btn
                      btn-xs
                      btn-icon
                      btn-circle
                      btn-white
                      btn-hover-text-primary
                      btn-shadow
                    "
                    data-action="cancel"
                    data-toggle="tooltip"
                    title="Cancel avatar"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span
                    class="
                      btn
                      btn-xs
                      btn-icon
                      btn-circle
                      btn-white
                      btn-hover-text-primary
                      btn-shadow
                    "
                    data-action="remove"
                    data-toggle="tooltip"
                    title="Remove avatar"
                    @click="current_photo = null"
                  >
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
              </div>
            </b-form-group>
          </div>

          <div class="col-md">

            <b-form-group
              id="input-group-name"
              label="Nama:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                placeholder="Nama"
              ></b-form-input>
              <small class="text-danger">{{ error.name }}</small>
            </b-form-group>

            <b-form-group id="input-group-address">
              <label for="input-address">Alamat:</label>
              <b-form-textarea
                id="input-address"
                v-model="form.address"
                placeholder="Alamat"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.address }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-phone"
              label="Telepon:"
              label-for="input-phone"
            >
              <b-form-input
                id="input-phone"
                v-model="form.phone"
                placeholder="Telepon"
              ></b-form-input>
              <small class="text-danger">{{ error.phone }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-email"
              label="Email:"
              label-for="input-email"
            >
              <b-form-input
                id="input-email"
                v-model="form.email"
                placeholder="Email"
              ></b-form-input>
              <small class="text-danger">{{ error.email }}</small>
            </b-form-group>

            <!-- Text input -->
            <b-form-group
              id="input-group-password"
              label="Password:"
              label-for="input-password"
              v-if="purpose == 'add'"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    @click="
                      passwordIsVisible == 'password'
                        ? (passwordIsVisible = 'text')
                        : (passwordIsVisible = 'password')
                    "
                  >
                    <div v-if="passwordIsVisible == 'password'">
                      <b-icon-eye-slash></b-icon-eye-slash>
                    </div>
                    <div v-if="passwordIsVisible == 'text'">
                      <b-icon-eye></b-icon-eye>
                    </div>
                  </b-input-group-text>
                </template>
                <b-form-input
                  id="input-password"
                  v-model="form.password"
                  placeholder="Password"
                  :type="passwordIsVisible"
                ></b-form-input>
              </b-input-group>
                <small class="text-danger">{{error.password}}</small>
            </b-form-group>

            <b-form-group
              id="input-group-role"
              label="Peran:"
              label-for="input-role"
            >
              <treeselect
                v-model="form.role_id"
                :multiple="false"
                placeholder="Pilih Peran"
                :options="roles"
              />
              <small class="text-danger">{{ error.role_id }}</small>
            </b-form-group>

          </div>
        </div>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push(routeBack)"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    routeBack: String,
    roleId: String,
    form: Object,
    routePost: String,
    currentPhoto: String,
    purpose: String,
    secondPurpose: String,
    title: String
  },
  data() {
    return {
      error: {
        address: "",
        email: "",
        email2: "",
        name: "",
        nickname: "",
        phone: "",
        photo: "",
        description: "",
        role_id: "",
        password: "",
        registration_number: "",
      },
      roles: [],
      current_photo: "/img/default/default-user.svg",
      default_photo: "/img/default/default-user.svg",
      majors: [],
      // other
      passwordIsVisible: "password",
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.photo = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo = "";
    },

    async getRoleOption() {
      let response = await module.setTreeSelect("roles");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          label: "Pilih Peran",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.routePost);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$emit("successPost");
      }
    },
  },
  mounted() {
    this.getRoleOption();

    this.current_photo = this.form.photo;
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      // watch it
      //("Form changed: ", newVal, " | was: ", oldVal);
      this.current_photo = this.form.photo;
      //   this.default_photo = this.form.photo
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>